body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  // touch-action: none;
  font-size: 15px; 
}

*{
  
  box-sizing: border-box; 
  padding: 0;
  margin: 0;
} 
#root { 
  background-color: white  ;
  max-width: 1020px;
  margin: 0 auto !important;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 100px;
}

input {
  background: none;
  outline: none;
  border: 0px;
}

input::placeholder {
  color: #ccc;
}

.vjs-big-play-button {
  background: #ffdd00 !important;
}

.am-tabs-default-bar-tab {
  white-space: nowrap;
}

img {
  max-width: 100%;
}

.am-picker-col-item {
  display: flex;
  height: 34px !important;
  line-height: 17px !important;
  white-space: unset !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px !important;

}

.am-picker-col-item-selected {
  font-size: 15px !important;
}

.am-list-item {
  background-color: unset !important;
}

.am-checkbox-agree .am-checkbox-inner {
  top: 15px !important
}

.am-modal-transparent {
  width: 80% !important;

}

.am-checkbox-agree {
  padding-left: 0
}

.Modal2 {
  .am-modal-transparent {
    width: 100% !important;

    .am-modal-body {
      padding: 0 !important
    }

  }

  .am-modal-content {
    background-color: unset;
  }

  .am-modal-close {
    background-color: white;
    border-radius: 15px;
    width: 30px;
    height: 30px
  }

}

.am-card-header-content {
  img {
    width: 18px
  }
}

.am-wingblank {
  .am-card-header-extra {
    flex: .5;

    img {
      width: auto;
      height: 50px
    }
  }
}