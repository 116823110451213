.container { 
  padding-top: 45px; 
  :global {}

  .main {
    height: 100%; 
    .box{
      padding-bottom: 30px;
    }
    .resultIcon {
      width: 50px;
      height: 50px;
    }


  }

}